
function NavTab({
    handleClickAboutUs,
    handleClickContacts,
}) {

    function clickAboutUs(e) {
        e.preventDefault();
        handleClickAboutUs();
    }

    function clickContacts(e) {
        e.preventDefault();
        handleClickContacts();
    }

    function closeNavTab() {  //свернуть попап
        document.querySelector('.navtab').classList.remove('navtab_visible')
    }

    // +79995655605

    return (
        <div className="navtab">
            <button className="navtab__button" onClick={closeNavTab}>
                <span className="navtab__button-close"></span>
            </button>
            <h1 className='mainPage__title'>DARK</h1>
            <h2 className='mainPage__subtitle'>dancing & plastica</h2>
            <button className={`navtab__button`} onClick={(e) => { clickAboutUs(e) }}>О нас</button>
            <button className={`navtab__button`} onClick={(e) => { clickContacts(e) }}>Контакты</button>
            <a className={`navtab__link`} href='https://lab.darkflex.ru/dark'> Веб - приложение</a >
            <button className={`navtab__button`} onClick={(e) => { }}>IOS-приложение (скоро)</button>
            <button className={`navtab__button`} onClick={(e) => { }}>Android-приложение (скоро)</button>
            {/* <button className={`navtab__button ${isMovies ? "navtab__button_border-show" : ""}`} onClick={(e) => { redirectPage('/movies'); handleClick(e) }}>Фото</button> * /}
            {/* <button className={`navtab__button ${isSavedMovies ? "navtab__button_border-show" : ""}`} onClick={(e) => { redirectPage('/saved-movies'); handleClick(e) }}>Политика конфиденциальности</button> */ }
        </div >
    )
}

export default NavTab;